import React from "react";
import hero_img from "./../assets/F1.png";

const HomeContainer = () => {
  return (
    <div className="max-w-[1640px] mx-auto p-4">
      <div className="max-h-[500px] relative">
        {/* {overlay} */}
        <div className="absolute w-full h-full text-gray-200 max-h-[500px] bg-black/40 flex flex-col justify-center rounded-lg">
          <h1 className="px-4 text-2xl sm:text-4xl md:text-5xl lg:text-6xl font-bold">
            Zi de zi,
            <span className="text-orange-500">
              ceea ce faci este ceea ce devii
            </span>
            . Alege sa{" "}
            <span className="text-orange-500">cumperi un partener</span> nu un
            produs!
          </h1>
        </div>

        <img
          className="w-full max-h-[500px] object-cover"
          src={hero_img}
          alt=""
        />
      </div>
    </div>
  );
};

export default HomeContainer;
