import React, { useRef } from "react";
import { GrInstagram } from "react-icons/gr";
import {
  BsEnvelopeCheckFill,
  BsFillCartPlusFill,
  BsWhatsapp,
  BsFacebook,
} from "react-icons/bs";
import { AiTwotoneMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_x2pmbyp",
      "template_tm8uekm",
      form.current,
      "BAcxfLL7GaPw_zebt"
    );
    e.target.reset();
  };
  return (
    <section
      id="contact"
      className="antialiased bg-gray-100 max-w-screen-2xl min-h-screen"
    >
      <div className="flex w-full min-h-screen justify-center items-center">
        <div className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0 bg-blue-900 w-full max-w-5xl p-8 sm:p-12 rounded-xl shadow-lg text-white">
          <div className="flex flex-col space-y-8 justify-between">
            <div>
              <h1 className="text-4xl font-bold tracking-wide inline-flex">
                <BsFillCartPlusFill className="mr-3 text-orange-500" />
                Comanda aici !
              </h1>
              <p className="pt-2 text-blue-100 text-sm">
                Suna, scrie-ne pe whatsup sau trimite un email cu produsele
                dorite si te contactam noi pentru mai multe detalii
              </p>
            </div>
            <div className="flex flex-col space-y-8">
              <div className="inline-flex space-x-2 items-center">
                <FiPhoneCall className="text-orange-500 text-xl" />
                <span>Numar de contact: 0724 530 278</span>
              </div>
              <div className="inline-flex space-x-2 items-center">
                <AiTwotoneMail className="text-orange-500 text-xl" />
                <span>Email: dnd.performanceshop@gmail.com</span>
              </div>
              <div className="inline-flex space-x-2 items-center">
                <BsWhatsapp className="text-orange-500 text-xl" />
                <span>Whatsapp: 0724 530 278</span>
              </div>
            </div>

            <div className="flex space-x-4 text-lg">
              <a target="_black" href="https://ro-ro.facebook.com/">
                <BsFacebook />
              </a>
              <a target="_black" href="https://www.instagram.com/">
                <GrInstagram />
              </a>
            </div>
          </div>
          <div>
            <div className="bg-white rounded-xl shadow-lg p-8 text-gray-600 md:w-80">
              <form
                ref={form}
                onSubmit={sendEmail}
                className="flex flex-col space-y-4"
              >
                <div>
                  <label htmlFor="name" className="text-sm">
                    Nume:
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Nume..."
                    className="ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-sky-400"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="text-sm">
                    Email:
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Adresa ta de Email..."
                    className="ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-sky-400"
                  />
                </div>
                <div>
                  <label htmlFor="" className="text-sm">
                    Scrie comanda aici:
                  </label>
                  <textarea
                    name="comanda"
                    rows="4"
                    placeholder="Scrie comanda aici noi primim email-ul tau si te contactam in cel mai scurt timp !"
                    className="ring-1 ring-gray-300 w-full rounded-md px-4 py-2 mt-2 outline-none focus:ring-2 focus:ring-sky-400"
                  ></textarea>
                </div>
                <motion.button
                  whileTap={{ scale: 1.2 }}
                  type="submit"
                  className="inline-block self-end bg-blue-900 text-white font-bold rounded-lg px-6 py-2"
                >
                  <BsEnvelopeCheckFill className="text-orange-500 inline-flex mr-2" />
                  Trimite comanda !
                </motion.button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
