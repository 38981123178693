import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAM3KeW9ncAbQPTMnzeroEuVSKaz68EAs4",
  authDomain: "dnd-website-94e93.firebaseapp.com",
  databaseURL:
    "https://dnd-website-94e93-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dnd-website-94e93",
  storageBucket: "dnd-website-94e93.appspot.com",
  messagingSenderId: "148844917500",
  appId: "1:148844917500:web:c4a29f17ce701014de1d79",
};
const app = getApps().length > 0 ? getApp() : initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const storage = getStorage(app);

export { app, firestore, storage };
