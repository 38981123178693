import React, { useEffect, useState } from "react";
import HomeContainer from "./HomeContainer";

import MenuContainer from "./MenuContainer";
import Contact from "./Contact";
import menaj from "./../assets/F2.png";
import caserole from "./../assets/caserole.png";
import farfuri_cutite from "./../assets/F4.png";
import DespreNoi from "./DespreNoi";

const MainContainer = () => {
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {}, [scrollValue]);

  return (
    <>
      <HomeContainer />

      <div className="">
        <p className="text-2xl font-semibold  text-blue-900 relative before:absolute before:rounded-lg before:content before:w-32 before:h-1 before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-orange-400 to-orange-600 transition-all ease-in-out duration-100 pb-3">
          Alege din diversitatea noilor produse DND
        </p>
      </div>

      <div className="max-w-[1640px] mx-auto p-4 py-12 grid md:grid-cols-3 gap-6">
        {/* card */}
        <div className="rounded-xl relative">
          {/* overlay */}

          <div className="absolute w-full h-full bg-black/50 rounded-xl text-white">
            <p className="font-bold text-2xl px-2 pt-4">
              Ambalaje, Caserole, Boluri, Farfurii
            </p>
            <p className="px-2">Impacheteaza-ti afacerea in bine !</p>
            <a href="#menu">
              <button className="border-white mx-2 absolute bottom-4 bg-orange-500 hover:bg-blue-900 drop-shadow-xl text-white font-bold rounded-xl px-6 py-2">
                Comanda !
              </button>
            </a>
          </div>

          <img
            className="max-h-[160px] md:max-h-[200px] w-full object-cover rounded-lg"
            src={caserole}
            alt=""
          />
        </div>

        <div className="rounded-xl relative">
          {/* overlay */}

          <div className="absolute w-full h-full bg-black/50 rounded-xl text-white">
            <p className="font-bold text-2xl px-2 pt-4">
              Tacamuri, Pahare, Hartie
            </p>
            <p className="px-2">Sigur ai nevoie !</p>
            <a href="#menu">
              <button className="border-white mx-2 absolute bottom-4 bg-orange-500 hover:bg-blue-900 drop-shadow-xl text-white font-bold rounded-xl px-6 py-2">
                Comanda !
              </button>
            </a>
          </div>
          <img
            className="max-h-[160px] md:max-h-[200px] w-full object-cover rounded-lg"
            src={farfuri_cutite}
            alt=""
          />
        </div>
        <div className="rounded-xl relative">
          {/* overlay */}

          <div className="absolute w-full h-full bg-black/50 rounded-xl text-white">
            <p className="font-bold text-2xl px-2 pt-4">Obiecte de menaj</p>
            <p className="px-2">Fa-ti o ambianta placuta</p>
            <a href="#menu">
              <button className="border-white mx-2 absolute bottom-4 bg-orange-500 hover:bg-blue-900 drop-shadow-xl text-white font-bold rounded-xl px-6 py-2">
                Comanda !
              </button>
            </a>
          </div>
          <img
            className="max-h-[160px] md:max-h-[200px] w-full object-cover rounded-lg"
            src={menaj}
            alt=""
          />
        </div>
      </div>

      <MenuContainer />
      <Contact />
      <DespreNoi />
    </>
  );
};

export default MainContainer;
