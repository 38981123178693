import React, { useState } from "react";
import { categories } from "../utils/data";
import { motion } from "framer-motion";
import RowContainer from "./RowContainer";
import { useStateValue } from "../context/StateProvider";

const MenuContainer = () => {
  const [filter, setFilter] = useState("ambalaje");

  const [{ productItems }, dispatch] = useStateValue();

  return (
    <section className="w-full my-6" id="menu">
      <div className="w-full flex flex-col items-center justify-center">
        <p className="text-2xl font-semibold capitalize text-blue-900 relative before:absolute before:rounded-lg before:content before:w-1/2 before:h-2 before:-bottom-2 before:left-0 before:bg-gradient-to-tr from-orange-500 to-blue-950 transition-all ease-in-out duration-100 mr-auto">
          Categorii de produse
        </p>

        <div className="w-full flex items-center justify-start lg:justify-center gap-6 mt-6 py-6 overflow-x-scroll scrollbar-none">
          {categories &&
            categories.map((category) => (
              <motion.div
                whileTap={{ scale: 0.75 }}
                key={category.id}
                className={`group ${
                  filter === category.urlParamName
                    ? "bg-blue-900"
                    : "bg-orange-500"
                } w-24 min-w-[94px] h-12 cursor-pointer rounded-lg drop-shadow-xl flex flex-col gap-3 items-center justify-center hover:bg-blue-900 `}
                onClick={() => setFilter(category.urlParamName)}
              >
                <p
                  className={`text-lg font-extrabold ${
                    filter === category.urlParamName
                      ? "text-orange-500"
                      : "text-white"
                  } group-hover:text-white`}
                >
                  {category.name}
                </p>
              </motion.div>
            ))}
        </div>
        <div className="w-full">
          <RowContainer
            flag={false}
            data={productItems?.filter((n) => n.category == filter)}
          />
        </div>
      </div>
    </section>
  );
};

export default MenuContainer;
