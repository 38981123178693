export const categories = [
  {
    id: 1,
    name: "Ambalaje",
    urlParamName: "ambalaje",
  },
  {
    id: 2,
    name: "Boluri",
    urlParamName: "boluri",
  },
  {
    id: 3,
    name: "Caserole",
    urlParamName: "caserole",
  },
  {
    id: 4,
    name: "Farfurii",
    urlParamName: "farfurii",
  },
  {
    id: 5,
    name: "Hartie",
    urlParamName: "hartie",
  },
  {
    id: 6,
    name: "Folii",
    urlParamName: "folii",
  },

  {
    id: 8,
    name: "Menaj",
    urlParamName: "menaj",
  },
  {
    id: 9,
    name: "Pahare",
    urlParamName: "pahare",
  },
  {
    id: 10,
    name: "Pungi",
    urlParamName: "pungi",
  },
  {
    id: 11,
    name: "Sosiere",
    urlParamName: "sosiere",
  },
  {
    id: 12,
    name: "Tacamuri",
    urlParamName: "tacamuri",
  },
];
