import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import NotFound from "../assets/notFound.png";
import { RiDeleteBin5Line } from "react-icons/ri";
import { useStateValue } from "../context/StateProvider";
import { app } from "../firebase.config";
import { getFirestore, collection, doc, deleteDoc } from "firebase/firestore";

const RowContainer = ({ flag, data, scrollValue }) => {
  const rowContainer = useRef();
  const [{ user }, dispatch] = useStateValue();
  const firestore = getFirestore(app);
  const productItemsCollection = collection(firestore, "productItems");

  useEffect(() => {
    rowContainer.current.scrollLeft += scrollValue;
  }, [scrollValue]);

  const handleDelete = async (itemId) => {
    try {
      await deleteDoc(doc(productItemsCollection, itemId));
      console.log("Document deleted successfully.");
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  return (
    <div
      ref={rowContainer}
      className={`w-full flex items-center gap-3  my-12 scroll-smooth  ${
        flag
          ? "overflow-x-scroll scrollbar-none"
          : "overflow-x-hidden flex-wrap justify-center"
      }`}
    >
      {data && data.length > 0 ? (
        data.map((item) => (
          <div
            key={item?.id}
            className="w-275 h-[300px] min-w-[275px] md:w-300 md:min-w-[300px]  bg-cardOverlay rounded-lg py-2 px-4  my-8 backdrop-blur-lg hover:drop-shadow-lg flex flex-col items-center justify-evenly relative"
          >
            {user && user.email === "dnd.performanceshop@gmail.com" && (
              <motion.button
                onClick={() => handleDelete(item.id)}
                className="absolute top-2 right-2"
                whileTap={{ scale: 1.2 }}
              >
                <RiDeleteBin5Line className="text-red-700 text-2xl cursor-pointer" />
              </motion.button>
            )}

            <div className="w-full flex items-center justify-between">
              <motion.div
                className="w-36 h-36 -mt-6 drop-shadow-2xl"
                whileHover={{ scale: 1.2 }}
              >
                <img
                  src={item?.imageURL}
                  alt=""
                  className="w-full h-full object-contain drop-shadow-2xl"
                />
              </motion.div>
            </div>

            <div className="w-full flex flex-col items-end justify-end -mt-2">
              <p className="text-textColor font-semibold text-base md:text-lg">
                {item?.title}
              </p>
              <p className="mt-1 text-sm text-blue-950">
                {item?.descriereProdus}
              </p>
              <div className="flex items-center gap-8">
                <p className="text-lg text-headingColor font-semibold">
                  {item?.price}
                  <span className="px-2 text-sm text-orange-500">RON</span>
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full flex flex-col items-center justify-center">
          <img src={NotFound} className="h-340" alt="not found" />
          <p className="text-xl text-headingColor font-semibold my-2">
            Items Not Available
          </p>
        </div>
      )}
    </div>
  );
};

export default RowContainer;
