import React from "react";
import { GrLocation } from "react-icons/gr";
import logo from "../assets/logo.png";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { AiTwotoneMail } from "react-icons/ai";

const DespreNoi = () => {
  return (
    <>
      <div
        id="desprenoi"
        className="bg-slate-200 w-full flex md:flex-col flex-col justify-around items-start p-10 rounded-xl"
      >
        <div className="p-5">
          <ul>
            <p className="text-gray-800 font-bold text-3xl pb-6">
              <img className="w-12 mr-2" src={logo} alt="logo" />
              <span>
                Performance <span className="text-blue-900">Shop</span> SRL
              </span>
            </p>
            <p className="mt-2 text-sm text-black italic">
              DND Performance Shop SRL are ca obiectiv de activitate distributia
              de ambalaje de unica folosinta, produse de curatenie si materiale
              consumabile. Ne dorim sa fim un ajutor de nadejde pentru afacerea
              dumneavoastra, oferindu-va o gama larga de ambalaje de carton,
              caserole si cutii meniu, pahare de unica folosinta, boluri supe si
              salata, cutii hamburgeri, hartie de ambalat, tacamuri de unica
              folosinta, produse de unica folosinta, produse de curatenie si
              materiale consumabile de cea mai buna calitate si pret corect.
            </p>
          </ul>
        </div>
        <div className="p-5 w-full ">
          <p className="text-gray-800 font-semibold text-xl pb-4">
            Informatii utile
          </p>
          <div>
            <p className="inline-flex space-x-2 items-center text-gray-500 text-md pb-2 font-semibold">
              <GrLocation className="text-xl" /> Adresa: Aleea Muzicii Nr.3,
              Timisoara
            </p>
          </div>
          <div>
            <p className="inline-flex space-x-2 items-center text-gray-500 text-md pb-2 font-semibold">
              <FiPhoneCall className="text-xl" />
              <span>Numar de contact: 0724 530 278</span>
            </p>
          </div>
          <div>
            <p className="inline-flex space-x-2 items-center text-gray-500 text-md pb-2 font-semibold">
              <AiTwotoneMail className="text-xl" />
              <span>Email: dnd.performanceshop@gmail.com</span>
            </p>
          </div>
        </div>
        <div className="flex gap-6 pb-5 pt-4">
          {/* section for icons */}
          <a target="_black" href="https://ro-ro.facebook.com/">
            <FaFacebook className="text-2xl cursor-pointer hover:text-orange-500" />
          </a>
          <a target="_black" href="https://www.instagram.com/">
            <FaInstagram className="text-2xl cursor-pointer hover:text-orange-500" />
          </a>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center text-center p-5 bg-slate-100">
        <h1 className="text-gray-800 text-sm">
          Copyright @ 2023-Diaconescu Ionut | DND Performance Shop.Toate
          drepturile rezervate.
        </h1>
      </div>
    </>
  );
};

export default DespreNoi;
